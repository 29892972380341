
// Vue imports
import { defineComponent } from 'vue';
// Vuetify imports
import { VSkeletonLoader } from 'vuetify/lib/labs/components.mjs';
// Local components
import ExerciseRoutineModal from '../modals/ExerciseRoutineModal.vue';
import ExerciseWorkoutModal from '../modals/ExerciseWorkoutModal.vue';
import AddMaxModal from '../modals/ExerciseView/AddMaxModal.vue';
import BookmarkButton from '../buttons/BookmarkButton.vue';
// Pinia stores
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            modeStore: useModeStore()
        });
    },
    components: {
        ExerciseRoutineModal,
        ExerciseWorkoutModal,
        AddMaxModal,
        BookmarkButton,
        VSkeletonLoader
    },
    props: ['exercise', 'muscles', 'equipment', 'isLoaded', 'isError']
});
