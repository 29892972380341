
// Vue imports
import { defineComponent } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Vuetify imports
import { VSkeletonLoader } from 'vuetify/lib/labs/components.mjs';
// Type interfaces
import { Muscle, Equipment, Category, Exercise, RecordData } from '@/types/index';
// Local components
import LoginBanner from '@/components/banners/LoginBanner.vue';
import ExerciseToolbar from '@/components/toolbars/ExerciseToolbar.vue';
import MyFooter from '@/components/MyFooter.vue';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useLoginStore } from '@/stores/loginStore';
import { useStatStore } from '@/stores/statStore';
import { useModeStore } from '@/stores/modeStore';
import { useExerciseStore } from '@/stores/exerciseStore';

const getData = async (url: string): Promise<any> => {
	return await fetch(url)
		.then(res => res.json())
		.catch(err => {
			console.log(err);
			throw new Error('Error retrieving data!');
		});
}

const getExerciseData = async (slug: string): Promise<Exercise | undefined> => {
	let slugArr = slug.split('-');

	for (let i = 0; i < slugArr.length; i++) {
		slugArr[i] = slugArr[i][0].toUpperCase() + slugArr[i].slice(1);
	}

	let newSlug = slugArr.join('+');

	// Attempt to fetch exercise data with default slug (all first letters uppercase)
	let exercise = await fetch(`https://wger.de/api/v2/exercise/?name=${newSlug}&language=2`)
		.then(res => res.json())
		.then(data => data.results[0])
		.catch(err => {
			console.log(err);
			throw new Error('Error retrieving data!');
		});

	if (exercise) return exercise;

	// Attempt to fetch exercise data with lowercase slug (all first letters lowercase)
	slugArr = slug.split('-');

	for (let i = 1; i < slugArr.length; i++) {
		slugArr[i] = slugArr[i][0].toLowerCase() + slugArr[i].slice(1);
	}

	newSlug = slugArr.join('+');

	exercise = await fetch(`https://wger.de/api/v2/exercise/?name=${newSlug}&language=2`)
		.then(res => res.json())
		.then(data => data.results[0])
		.catch(err => {
			console.log(err);
			throw new Error('Error retrieving data!');
		});

	if (exercise) return exercise;

	// Attempt to fetch exercise data by changing case for each first letter of each word
	for (let i = 0; i < slugArr.length; i++) {
		slugArr = slug.split('-');

		for (let i = 0; i < slugArr.length; i++) {
			slugArr[i] = slugArr[i][0].toUpperCase() + slugArr[i].slice(1);
		}

		slugArr[i] = slugArr[i][0].toLowerCase() + slugArr[i].slice(1);
		newSlug = slugArr.join('+');

		exercise = await fetch(`https://wger.de/api/v2/exercise/?name=${newSlug}&language=2`)
			.then(res => res.json())
			.then(data => data.results[0])
			.catch(err => {
				console.log(err);
				throw new Error('Error retrieving data!');
			});

		if (exercise) return exercise;
	}

	// Attempt to fetch exercise data by turning each letter to opposite case
	for (let i = 0; i < slug.length; i++) {
		const slugArr = slug.replaceAll('-', '+').split('');

		if (slugArr[i] === slug.charAt(i).toUpperCase()) {
			slugArr[i] = slug.charAt(i).toLowerCase();
		} else {
			slugArr[i] = slug.charAt(i).toUpperCase();
		}

		if (slugArr[i] === '+') slugArr[i] = '-';
		newSlug = slugArr.join('');

		exercise = await fetch(`https://wger.de/api/v2/exercise/?name=${newSlug}&language=2`)
			.then(res => res.json())
			.then(data => data.results[0])
			.catch(err => {
				console.log(err);
				throw new Error('Error retrieving data!');
			});

		if (exercise) return exercise;
	}

	// Attempt to fetch exercise data by turning each letter of lowercase string to opposite case
	for (let i = 0; i < slug.length; i++) {
		const slugArr = slug.toLowerCase().replaceAll('-', '+').split('');

		if (slugArr[i] === slug.charAt(i).toUpperCase()) {
			slugArr[i] = slug.charAt(i).toLowerCase();
		} else {
			slugArr[i] = slug.charAt(i).toUpperCase();
		}

		if (slugArr[i] === '+') slugArr[i] = '-';
		newSlug = slugArr.join('');

		exercise = await fetch(`https://wger.de/api/v2/exercise/?name=${newSlug}&language=2`)
			.then(res => res.json())
			.then(data => data.results[0])
			.catch(err => {
				console.log(err);
				throw new Error('Error retrieving data!');
			});

		if (exercise) return exercise;
	}
}

export default defineComponent({
	data () {
		// Vue Queries
		const exercise = useQuery('exercise', () => getExerciseData(this.$route.params.id as string));
		const muscles = useQuery('muscles', () => getData('https://wger.de/api/v2/muscle?limit=999'));
		const equipment = useQuery('equipment', () => getData('https://wger.de/api/v2/equipment?limit=999'));
		const images = useQuery(['images', this.exerciseBase], () => getData(`https://wger.de/api/v2/exerciseimage/?limit=999&exercise_base=${this.exerciseBase}`), { enabled: false });

		const displayName = (this.$route.params.id as string).split('-').map(word => {
				return word[0].toUpperCase() + word.slice(1);
			}).join(' ');

		return ({
			exercise,
			muscles,
			equipment,
			images,
			categories: [] as Category[],
			menuOpen: false,
			routerName: this.$route.params.id as string,
			exerciseBase: 0,
			error: true,
			loading: true,
			displayName,
			workoutStore: useWorkoutStore(),
			loginStore: useLoginStore(),
			statStore: useStatStore(),
			modeStore: useModeStore(),
			exerciseStore: useExerciseStore()
		});
	},
	components: {
		LoginBanner,
		ExerciseToolbar,
		MyFooter,
		VSkeletonLoader
	},
	methods: {
		getMuscleName (item: number) {
			const muscle = this.muscles.data.results.find((muscle: Muscle) => muscle.id === item);

			if (!muscle) return '';
				else if (muscle.name_en) {
					return muscle.name_en;
				} else return muscle.name;
		},
		getEquipmentName (item: number) {
			const equipment = this.equipment.data.results.find((piece: Equipment) => piece.id === item);

			if (!equipment) return '';
				else return equipment.name;
		},
		setMenuOpen () {
			this.menuOpen = !this.menuOpen;
		},
		getSlug (name: string) {
			return name.split(' ').join('-').replaceAll('(', '').replaceAll(')', '').toLowerCase();
		},
		getIcon (folder: string, name: string) {
			return {
				backgroundImage: `url('/images/${folder}/${this.getSlug(name)}.webp')`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				backgroundPosition: 'center',
				content: '',
				width: '32px',
				height: '32px',
				display: 'inline-block',
				marginRight: '8px',
				filter: this.modeStore.darkMode ? 'invert(1)' : ''
			};
		}
	},
	computed: {
		isLoaded () {
			if (this.exercise.isLoading || this.muscles.isLoading || this.equipment.isLoading || this.images.isLoading) {
				return false;
			} else return true;
		},
		isError () {
			if (this.exercise.isError || this.muscles.isError || this.equipment.isError || this.images.isError) {
				return true;
			} else return false;
		},
		workouts () {
			const workouts: any[] = [];

			for (const workout of this.workoutStore.workouts) {
				for (const entry of workout.attributes.entries) {
					if (entry.name === this.exercise.data?.name) {
						const sortedSets = entry.sets.sort((a, b) => {
							return b.weight - a.weight;
						})

						workouts.push({
							date: workout.attributes.date,
							weight: sortedSets[0].weight,
							reps: sortedSets[0].reps
						});
					}
				}
			}

			return workouts;
		},
		records (): RecordData[] {
			if (this.exercise.data) {
				return this.statStore.records.filter(record => record.attributes.exercise === this.exercise.data?.exercise_base);
			} else {
				return [];
			}
		}
	},
	watch: {
		exercise: {
			deep: true,
			handler () {
				if (this.exercise.isSuccess && this.exercise.data) {
					this.exerciseStore.exercise = this.exercise.data;
					this.exerciseBase = this.exercise.data.exercise_base || 0;
					this.images.refetch();
				}
			}
		}
	},
	beforeMount () {
		document.title = `${this.displayName} - Lift Shark`;
	}
});
