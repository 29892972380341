
// Vue imports
import { defineComponent } from 'vue';
// Third-party libraries
import Cookies from 'js-cookie';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useRoutineStore } from '@/stores/routineStore';
import { useWindowStore } from '@/stores/windowStore';
import { useLoginStore } from '@/stores/loginStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import WorkoutCard from '@/components/cards/WorkoutCard.vue';
import LoginBanner from '@/components/banners/LoginBanner.vue';
import WorkoutToolbar from '@/components/toolbars/WorkoutToolbar.vue';
import MyFooter from '@/components/MyFooter.vue';
import InfoAlert from '@/components/alerts/InfoAlert.vue';
// Type interfaces
import { Workout } from '@/types/index';

export default defineComponent({
	data () {
		return ({
			workoutStore: useWorkoutStore(),
			routineStore: useRoutineStore(),
			windowStore: useWindowStore(),
			loginStore: useLoginStore(),
			modeStore: useModeStore(),
			showSnackBar: false,
			routineName: ''
		});
	},
	components: {
		WorkoutCard,
		LoginBanner,
		WorkoutToolbar,
		MyFooter,
		InfoAlert
	},
	methods: {
		updateUserToken () {
			if (this.loginStore.token !== Cookies.get('token')) {
				this.loginStore.token = Cookies.get('token');
			}
		},
		routinesOfDay (day: string) {
			return this.routineStore.routines.filter(routine => {
				return routine.attributes.day === day;
			});
		},
		useRoutine () {
			const routine = this.routineStore.getRoutineByName(this.routineName);

			if (routine) {
				const workout = ({
					date: this.workoutStore.date.toLocaleDateString('en-CA').split('T')[0],
					entries: routine.attributes.entries
				} as Workout);

				this.workoutStore.addWorkout(workout);
			}
		}
	},
	created () {
		window.setInterval(this.updateUserToken, 100); // Routinely check if user signs in or out
	}
})
