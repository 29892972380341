import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "mx-0 px-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "auto",
            class: "my-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Set #" + _toDisplayString(_ctx.set.key + 1), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, { class: "d-flex bg-blue-grey-darken-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "px-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "px-1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                "hide-details": "auto",
                                density: "compact",
                                type: "number",
                                label: "Count",
                                suffix: "reps",
                                min: 1,
                                max: 100,
                                "v-model": _ctx.modal === 'workout' ? _ctx.workoutStore.entries[_ctx.entry.key].sets[_ctx.set.key].reps : _ctx.routineStore.entries[_ctx.entry.key].sets[_ctx.set.key].reps
                              }, null, 8, ["v-model"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "px-1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                "hide-details": "auto",
                                density: "compact",
                                type: "number",
                                label: "Weight",
                                suffix: "lbs",
                                min: 0,
                                max: 1000,
                                "v-model": _ctx.modal === 'workout' ? _ctx.workoutStore.entries[_ctx.entry.key].sets[_ctx.set.key].weight : _ctx.routineStore.entries[_ctx.entry.key].sets[_ctx.set.key].weight
                              }, null, 8, ["v-model"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}