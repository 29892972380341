import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VSkeletonLoader = _resolveComponent("VSkeletonLoader")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_AddMaxModal = _resolveComponent("AddMaxModal")!
  const _component_ExerciseRoutineModal = _resolveComponent("ExerciseRoutineModal")!
  const _component_ExerciseWorkoutModal = _resolveComponent("ExerciseWorkoutModal")!
  const _component_BookmarkButton = _resolveComponent("BookmarkButton")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    extension: _withCtx(() => [
      _createVNode(_component_v_tooltip, { text: "Back to Exercises" }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps(props, {
            variant: "plain",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
            class: "pa-0 ma-0 d-flex justify-center align-center"
          }), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-chevron-left",
                size: "xxx-large"
              })
            ]),
            _: 2
          }, 1040)
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_toolbar_items, null, {
        default: _withCtx(() => [
          _createVNode(_component_AddMaxModal),
          _createVNode(_component_ExerciseRoutineModal),
          _createVNode(_component_ExerciseWorkoutModal),
          (_ctx.exercise.isSuccess && _ctx.exercise.data)
            ? (_openBlock(), _createBlock(_component_BookmarkButton, {
                key: 0,
                id: _ctx.exercise.data.id
              }, null, 8, ["id"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex text-center pa-0 ma-0" }, {
        default: _withCtx(() => [
          (_ctx.exercise.isLoading || _ctx.exercise.isFetching)
            ? (_openBlock(), _createBlock(_component_VSkeletonLoader, {
                key: 0,
                type: "list-item-avatar",
                color: "transparent"
              }))
            : (_ctx.exercise.isSuccess && _ctx.exercise.data)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.exercise.data.name), 1))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}