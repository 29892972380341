import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aa3b4b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  class: "pa-3"
}
const _hoisted_3 = {
  key: 1,
  class: "pa-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExerciseToolbar = _resolveComponent("ExerciseToolbar")!
  const _component_LoginBanner = _resolveComponent("LoginBanner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VSkeletonLoader = _resolveComponent("VSkeletonLoader")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-grey-darken-4' : 'bg-blue-lighten-4')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-start w-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ExerciseToolbar, {
                    exercise: _ctx.exercise,
                    muscles: _ctx.muscles,
                    equipment: _ctx.equipment,
                    isLoaded: _ctx.isLoaded,
                    isError: _ctx.isError
                  }, null, 8, ["exercise", "muscles", "equipment", "isLoaded", "isError"]),
                  (!_ctx.loginStore.token)
                    ? (_openBlock(), _createBlock(_component_LoginBanner, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "w-100 ma-0 align-self-stretch"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: 12,
                class: "content pa-3 d-flex flex-wrap justify-center align-flex-start",
                style: {gap: '20px'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: "400"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-arm-flex",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" Muscles ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.muscles.isLoading || _ctx.exercise.isFetching || _ctx.muscles.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.muscles.isSuccess && _ctx.exercise.data && _ctx.muscles.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, {
                                  density: "compact",
                                  class: "bg-transparent d-flex flex-wrap justify-center align-center",
                                  disabled: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_subheader, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Primary Muscles")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_sheet, {
                                      width: 300,
                                      class: "bg-transparent d-flex flex-wrap justify-center align-center"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercise.data.muscles, (muscle, i) => {
                                          return (_openBlock(), _createBlock(_component_v_list_item, {
                                            key: i,
                                            value: _ctx.getMuscleName(muscle),
                                            color: "primary"
                                          }, {
                                            prepend: _withCtx(() => [
                                              _createElementVNode("span", {
                                                style: _normalizeStyle(_ctx.getIcon('muscles', _ctx.getSlug(_ctx.getMuscleName(muscle))))
                                              }, null, 4)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, {
                                                textContent: _toDisplayString(_ctx.getMuscleName(muscle))
                                              }, null, 8, ["textContent"])
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        (!_ctx.exercise.data.muscles.length)
                                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                                              key: 0,
                                              color: "primary",
                                              disabled: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_list_item_title, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("None")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_list, {
                                  density: "compact",
                                  class: "bg-transparent d-flex flex-wrap justify-center align-center",
                                  disabled: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_subheader, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Secondary Muscles")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_sheet, {
                                      width: 300,
                                      class: "bg-transparent d-flex flex-wrap justify-center align-center"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercise.data.muscles_secondary, (muscle, i) => {
                                          return (_openBlock(), _createBlock(_component_v_list_item, {
                                            key: i,
                                            value: _ctx.getMuscleName(muscle),
                                            color: "primary"
                                          }, {
                                            prepend: _withCtx(() => [
                                              _createElementVNode("span", {
                                                style: _normalizeStyle(_ctx.getIcon('muscles', _ctx.getSlug(_ctx.getMuscleName(muscle).split(' ')[0])))
                                              }, null, 4)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, {
                                                textContent: _toDisplayString(_ctx.getMuscleName(muscle).split(' ')[0])
                                              }, null, 8, ["textContent"])
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        (!_ctx.exercise.data.muscles_secondary.length)
                                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                                              key: 0,
                                              color: "primary",
                                              disabled: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_list_item_title, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("None")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: "400"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-dumbbell",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" Equipment ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.equipment.isLoading || _ctx.exercise.isFetching || _ctx.equipment.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.equipment.isSuccess && _ctx.exercise.data && _ctx.equipment.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, {
                                  density: "compact",
                                  class: "bg-transparent d-flex flex-wrap justify-center align-center",
                                  disabled: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_sheet, {
                                      width: 300,
                                      class: "bg-transparent d-flex flex-wrap justify-center align-center"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercise.data.equipment, (item, i) => {
                                          return (_openBlock(), _createBlock(_component_v_list_item, {
                                            key: i,
                                            value: _ctx.getEquipmentName(item),
                                            color: "primary"
                                          }, {
                                            prepend: _withCtx(() => [
                                              _createElementVNode("span", {
                                                style: _normalizeStyle(_ctx.getIcon('equipment', _ctx.getSlug(_ctx.getEquipmentName(item))))
                                              }, null, 4)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list_item_title, {
                                                textContent: _toDisplayString(_ctx.getEquipmentName(item))
                                              }, null, 8, ["textContent"])
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128)),
                                        (!_ctx.exercise.data.equipment.length)
                                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                                              key: 0,
                                              color: "primary",
                                              disabled: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_list_item_title, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("None")
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: "400"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-information",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" Description ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.exercise.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.exercise.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100'),
                              innerHTML: _ctx.exercise.data.description || '<span>None</span>'
                            }, null, 8, ["class", "innerHTML"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: "400"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-image-area",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" Images ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.images.isLoading || _ctx.exercise.isFetching || _ctx.images.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.images.isSuccess && _ctx.exercise.data && _ctx.images.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                            }, {
                              default: _withCtx(() => [
                                (_ctx.images.data && _ctx.images.data.results.length)
                                  ? (_openBlock(), _createBlock(_component_v_carousel, {
                                      key: 0,
                                      class: "bg-white"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images.data.results, (image) => {
                                          return (_openBlock(), _createBlock(_component_v_carousel_item, {
                                            key: image.id,
                                            class: "h-100"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_img, {
                                                alt: "Exercise Example",
                                                src: image.image,
                                                "max-width": 250,
                                                class: "mx-auto h-100"
                                              }, null, 8, ["src"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (!_ctx.images.data || !_ctx.images.data.results.length)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, "No Images Available"))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: "400"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-weight-lifter",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" Workout History ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.exercise.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.exercise.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                            }, {
                              default: _withCtx(() => [
                                (_ctx.workouts.length)
                                  ? (_openBlock(), _createBlock(_component_v_table, {
                                      key: 0,
                                      class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-4 text-white' : 'bg-blue-grey-lighten-4 text-black')
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("thead", null, [
                                          _createElementVNode("tr", null, [
                                            _createElementVNode("th", {
                                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'text-white' : 'text-black')
                                            }, " Date ", 2),
                                            _createElementVNode("th", {
                                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'text-white' : 'text-black')
                                            }, " Record (lbs) ", 2),
                                            _createElementVNode("th", {
                                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'text-white' : 'text-black')
                                            }, " Record (reps) ", 2)
                                          ])
                                        ]),
                                        _createElementVNode("tbody", null, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workouts, (workout) => {
                                            return (_openBlock(), _createElementBlock("tr", {
                                              key: workout.id
                                            }, [
                                              _createElementVNode("td", null, _toDisplayString(workout.date), 1),
                                              _createElementVNode("td", null, _toDisplayString(workout.weight) + " lbs", 1),
                                              _createElementVNode("td", null, _toDisplayString(workout.reps) + " reps", 1)
                                            ]))
                                          }), 128))
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["class"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_2, "No workout history for this exercise"))
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    width: 400
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3' : 'bg-blue-lighten-2')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-medal",
                            size: "xx-large",
                            class: "mx-5"
                          }),
                          _createTextVNode(" 1-Rep Max ")
                        ]),
                        _: 1
                      }, 8, ["class"]),
                      (_ctx.exercise.isLoading || _ctx.exercise.isFetching)
                        ? (_openBlock(), _createBlock(_component_v_card_text, {
                            key: 0,
                            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_VSkeletonLoader, {
                                type: "paragraph",
                                color: "transparent"
                              })
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : (_ctx.exercise.isSuccess && _ctx.exercise.data)
                          ? (_openBlock(), _createBlock(_component_v_card_text, {
                              key: 1,
                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-1 pa-2 h-100' : 'bg-blue-lighten-3 pa-2 h-100')
                            }, {
                              default: _withCtx(() => [
                                (_ctx.statStore.records.length)
                                  ? (_openBlock(), _createBlock(_component_v_table, {
                                      key: 0,
                                      class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-4 text-white' : 'bg-blue-grey-lighten-4 text-black')
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("thead", null, [
                                          _createElementVNode("tr", null, [
                                            _createElementVNode("th", {
                                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'text-white' : 'text-black')
                                            }, " Date ", 2),
                                            _createElementVNode("th", {
                                              class: _normalizeClass(_ctx.modeStore.darkMode ? 'text-white' : 'text-black')
                                            }, " Max (lbs) ", 2)
                                          ])
                                        ]),
                                        _createElementVNode("tbody", null, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statStore.records, (record) => {
                                            return (_openBlock(), _createElementBlock("tr", {
                                              key: record.id
                                            }, [
                                              _createElementVNode("td", null, _toDisplayString(record.attributes.date), 1),
                                              _createElementVNode("td", null, _toDisplayString(record.attributes.max) + " lbs", 1)
                                            ]))
                                          }), 128))
                                        ])
                                      ]),
                                      _: 1
                                    }, 8, ["class"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_3, "No 1-rep max logged for this exercise"))
                              ]),
                              _: 1
                            }, 8, ["class"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.isError)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                "no-gutters": "",
                class: "w-100 ma-0 align-self-stretch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_alert, {
                        "max-width": 650,
                        border: "start",
                        "border-color": "red-accent-1",
                        elevation: "2",
                        type: "error",
                        title: "Error",
                        text: "There is an issue communicating with the server. Please try again later."
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MyFooter)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}