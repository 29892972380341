
// Vue imports
import { defineComponent, PropType } from 'vue';
// Type imports
import { Entry, Set } from '@/types/index';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useRoutineStore } from '@/stores/routineStore';

export default defineComponent({
    data () {
        return ({
            workoutStore: useWorkoutStore(),
            routineStore: useRoutineStore()
        });
    },
    props: {
        entry: {
            type: Object as PropType<Entry>,
            required: true
        },
        set: {
            type: Object as PropType<Set>,
            required: true
        },
        modal: {
            type: String as PropType<string>,
            required: true
        }
    }
})
