
// Vue imports
import { defineComponent } from 'vue';
// Local components
import AddRoutineModal from '../modals/WorkoutsView/AddRoutineModal.vue';
import AddWorkoutModal from '../modals/WorkoutsView/AddWorkoutModal.vue';
import CalendarModal from '../modals/CalendarModal.vue';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            workoutStore: useWorkoutStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore()
        });
    },
    components: {
        AddRoutineModal,
        AddWorkoutModal,
        CalendarModal
    }
})
