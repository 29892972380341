import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 3 }
const _hoisted_2 = { key: 4 }
const _hoisted_3 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex text-center" }, {
        default: _withCtx(() => [
          (_ctx.isMorning)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                icon: "mdi-weather-sunny",
                size: "xxx-large",
                class: "mr-3"
              }))
            : (_ctx.isNoon)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  icon: "mdi-weather-cloudy",
                  size: "xxx-large",
                  class: "mr-3"
                }))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 2,
                  icon: "mdi-weather-night",
                  size: "xxx-large",
                  class: "mr-3"
                })),
          (_ctx.isMorning)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Good morning, Alex!"))
            : (_ctx.isNoon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Good afternoon, Alex!"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "Good evening, Alex!"))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}