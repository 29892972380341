
// Vue imports
import { defineComponent } from 'vue';
// Local components
import DesktopNavBar from '../navbars/DesktopNavBar.vue';

export default defineComponent({
    components: {
        DesktopNavBar
    }
});
