
// Vue imports
import { defineComponent, PropType } from 'vue';
// Pinia stores
import { useExerciseStore } from '@/stores/exerciseStore';

export default defineComponent({
    data () {
        const exerciseStore = useExerciseStore();

        return ({
            exerciseStore
        });
    },
    props: {
        id: {
            type: Number as PropType<number>,
            required: true
        }
    },
    computed: {
        isFavorited () {
            const isFavorited = this.exerciseStore.favorites.find(entry => entry.attributes.exercise_base === this.id);

            if (isFavorited) return true;
                else return false;
        }
    }
});
