
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useLoginStore } from '@/stores/loginStore';
import { useWindowStore } from '@/stores/windowStore';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';
import StrongButton from '@/components/buttons/StrongButton.vue';
import GenericButton from '@/components/buttons/GenericButton.vue';
// Third-party libraries
import Cookies from 'js-cookie';

export default defineComponent({
	data () {
		const loginStore = useLoginStore();
		const windowStore = useWindowStore();

		return ({
			loginStore,
			windowStore,
			token: Cookies.get('token'),
			dialog: false,
			Cookies
		});
	},
	components: {
		CloseButton,
		StrongButton,
		GenericButton
	},
	methods: {
		removeToken () {
			Cookies.remove('token');
			this.loginStore.token = null;
			this.dialog = false;
		}
	}
});
