
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';
// Third-party components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineComponent({
	data () {
		const workoutStore = useWorkoutStore();
		const windowStore = useWindowStore();
		const modeStore = useModeStore();

		return ({
			workoutStore,
			windowStore,
			modeStore,
			dialog: false
		});
	},
	components: {
		CloseButton,
		VueDatePicker
	},
	computed: {
        dateIsToday () {
			const date = new Date();

            if (date.getFullYear() === this.workoutStore.date.getFullYear() &&
				date.getMonth() === this.workoutStore.date.getMonth() &&
				date.getDate() === this.workoutStore.dateVal) {
				return true;
			} else {
				return false;
			}
        }
    },
	methods: {
		handleDate (modelData) {
			this.workoutStore.date = modelData;
			this.workoutStore.dateVal = this.workoutStore.date.getDate();
			this.workoutStore.dateString = modelData.toLocaleDateString('en-CA').split('T')[0];
			this.dialog = false;
		},
		mergeProps
	}
});
