
// Vue imports
import { defineComponent, PropType } from 'vue';
// Type interfaces
import { WorkoutData, RoutineData } from '@/types/index';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useRoutineStore } from '@/stores/routineStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import DeleteButton from '@/components/buttons/DeleteButton.vue';

export default defineComponent({
	data () {
		return ({
			workoutStore: useWorkoutStore(),
			routineStore: useRoutineStore(),
			modeStore: useModeStore()
		});
	},
	components: {
		DeleteButton
	},
	props: {
		workout: {
			type: Object as PropType<WorkoutData>,
			required: false
		},
		routine: {
			type: Object as PropType<RoutineData>,
			required: false
		},
		preview: {
			type: Boolean as PropType<boolean>,
			required: true
		}
	},
	methods: {
		deleteItem () {
			if (this.workout) {
				this.workoutStore.deleteWorkout(this.workout.id);
			} else if (this.routine) {
				this.routineStore.deleteRoutine(this.routine.id);
			}
		}
	},
	computed: {
		entries () {
			if (this.workout) {
				return this.workout.attributes.entries;
			} else if (this.routine) {
				return this.routine.attributes.entries;
			} else return [];
		}
	}
});
