
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Type interfaces
import { ExerciseData, Exercise } from '@/types/index';
// Pinia stores
import { useRoutineStore } from '@/stores/routineStore';
import { useWindowStore } from '@/stores/windowStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import CloseButton from '../buttons/CloseButton.vue';
import ExerciseForm from './WorkoutModal/ExerciseForm.vue';

const getData = async (): Promise<ExerciseData> => {
	return await fetch('https://wger.de/api/v2/exercise?limit=999&language=2')
		.then(res => res.json())
		.catch(err => console.log(err));
}

export default defineComponent({
    data () {
		const exerciseQuery = useQuery('exercises', () => getData());

        return ({
            routineStore: useRoutineStore(),
			windowStore: useWindowStore(),
			snackbarStore: useSnackbarStore(),
			modeStore: useModeStore(),
			exerciseQuery,
			entryCount: 1,
			dialog: false,
			snackbar: false,
			name: 'New Routine'
        });
    },
    components: {
        CloseButton,
		ExerciseForm
    },
	methods: {
		addRoutine (e: MouseEvent) {
			e.preventDefault();

			const routine = ({
				name: this.name,
				day: this.routineStore.weekdays[this.routineStore.activeDay],
				entries: this.routineStore.entries
			});

			this.routineStore.addRoutine(routine);
			this.dialog = false;
			this.snackbarStore.text = 'Routine successfully added';
			this.snackbarStore.color = 'success';
			this.snackbarStore.icon = 'mdi-check';
			this.snackbarStore.open = true;
		},
		checkRoutines () {
			const numOfRoutines = this.routineStore.routines.filter(routine => routine.attributes.day === this.routineStore.weekdays[this.routineStore.activeDay]);

			if (numOfRoutines.length === 2) {
				this.dialog = false;
				this.snackbarStore.text = 'You already have 2 routines for this day!';
				this.snackbarStore.color = 'error';
				this.snackbarStore.icon = 'mdi-alert-circle';
				this.snackbarStore.open = true;
			}
		},
		mergeProps
	},
	computed: {
		filteredExercises (): Exercise[] {
			if (this.exerciseQuery.data) {
				const filtered = Array.from(this.exerciseQuery.data.results);

				for (let i = 0; i < filtered.length - 1; i++) {
					if (filtered[i].name === filtered[i + 1].name) {
						filtered.splice(i + 1, 1);
					}
				}

				return filtered;
			} else {
				return [];
			}
		}
	}
});
