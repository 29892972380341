
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Pinia stores
import { useStatStore } from '@/stores/statStore';
import { useExerciseStore } from '@/stores/exerciseStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';
// Type interfaces
import { ExerciseData } from '@/types';
// Third-party components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const getData = async (url: string): Promise<ExerciseData> => {
	return await fetch(url)
		.then(res => res.json())
		.catch(err => console.log(err));
}

export default defineComponent({
    data () {
		const { error, isError, isLoading, isFetching, isSuccess, data, refetch } = useQuery('exercises', () => getData('https://wger.de/api/v2/exercise/?language=2&limit=999'));

        return ({
            statStore: useStatStore(),
			exerciseStore: useExerciseStore(),
			snackbarStore: useSnackbarStore(),
			windowStore: useWindowStore(),
			modeStore: useModeStore(),
			dialog: false,
			weight: 0,
			muscle: 'Upper Arm',
			measurement: 0,
			error,
			isError,
			isLoading,
			isFetching,
			isSuccess,
			data,
			refetch,
			exercise: '',
			record: 0,
			tab: 'weight'
        });
    },
    components: {
        CloseButton,
		VueDatePicker
    },
	methods: {
		addStat (e: MouseEvent) {
			e.preventDefault();

			switch (this.statStore.tab) {
				case 'weight':
					this.addWeight();
					break;
				case 'measurement':
					this.addMeasurement();
					break;
				case 'record':
					this.addRecord();
					break;
				default:
					break;
			}
		},
		addWeight () {
			const weightExists = this.statStore.weights.find(weight => weight.attributes.date === this.statStore.date.toLocaleDateString('en-CA').split('T')[0]);

			if (weightExists) this.statStore.deleteWeight(weightExists.id);

			this.statStore.addWeight(this.weight);
			this.dialog = false;
			this.snackbarStore.text = 'Weight successfully added';
			this.snackbarStore.color = 'success';
			this.snackbarStore.icon = 'mdi-check';
			this.snackbarStore.open = true;
		},
		addMeasurement () {
			const measurementExists = this.statStore.measurements.find(measurement => {
				if (measurement.attributes.date === this.statStore.date.toLocaleDateString('en-CA').split('T')[0] &&
				measurement.attributes.muscle === this.muscle) {
					return true;
				} else return false;
			});

			if (measurementExists) this.statStore.deleteMeasurement(measurementExists.id);

			this.statStore.addMeasurement(this.muscle, this.measurement);
			this.dialog = false;
			this.snackbarStore.text = 'Measurement successfully added';
			this.snackbarStore.color = 'success';
			this.snackbarStore.icon = 'mdi-check';
			this.snackbarStore.open = true;
		},
		addRecord () {
			const exercise = this.data?.results.find(exercise => exercise.name === this.exercise);
			if (!exercise) return;

			const recordExists = this.statStore.records.find(record => {
				if (record.attributes.date === this.statStore.date.toLocaleDateString('en-CA').split('T')[0] &&
				record.attributes.exercise === exercise.exercise_base) {
					return true;
				} else return false;
			});

			if (recordExists) this.statStore.deleteRecord(recordExists.id);

			this.statStore.addRecord(exercise.exercise_base, this.record);
			this.dialog = false;
			this.snackbarStore.text = 'Record successfully added';
			this.snackbarStore.color = 'success';
			this.snackbarStore.icon = 'mdi-check';
			this.snackbarStore.open = true;
		},
		handleDate (modelData) {
			this.statStore.date = modelData;
			this.statStore.dateVal = this.statStore.date.getDate();
			this.statStore.dateString = modelData.toLocaleDateString('en-CA').split('T')[0];
		},
		mergeProps
	}
})
