import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    text: _ctx.isFavorited ? 'Remove from Favorites' : 'Add to Favorites'
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        title: _ctx.isFavorited ? 'Remove from Favorites' : 'Add to Favorites',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFavorited ? _ctx.exerciseStore.removeFromFavorites(_ctx.id) : _ctx.exerciseStore.addToFavorites(_ctx.id)))
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            icon: _ctx.isFavorited ? 'mdi-heart' : 'mdi-heart-plus',
            size: "xx-large",
            color: _ctx.isFavorited ? 'pink' : ''
          }, null, 8, ["icon", "color"])
        ]),
        _: 2
      }, 1040, ["title"])
    ]),
    _: 1
  }, 8, ["text"]))
}