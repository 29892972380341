import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_switch = _resolveComponent("v-switch")!

  return (_openBlock(), _createBlock(_component_v_switch, {
    inset: "",
    color: "info",
    onClick: _ctx.modeStore.toggleDarkMode,
    class: "d-inline-flex justify-center align-center"
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_v_icon, {
        icon: "mdi-weather-sunny",
        color: _ctx.modeStore.darkMode ? 'white' : 'red',
        style: {marginRight: '-8px'}
      }, null, 8, ["color"])
    ]),
    append: _withCtx(() => [
      _createVNode(_component_v_icon, {
        icon: "mdi-moon-waning-crescent",
        color: _ctx.modeStore.darkMode ? 'info' : 'black',
        style: {marginLeft: '-8px'}
      }, null, 8, ["color"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}