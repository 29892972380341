import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsToolbar = _resolveComponent("StatsToolbar")!
  const _component_LoginBanner = _resolveComponent("LoginBanner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_WeightCard = _resolveComponent("WeightCard")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_MeasurementCard = _resolveComponent("MeasurementCard")!
  const _component_PRCard = _resolveComponent("PRCard")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-grey-darken-4' : 'bg-blue-lighten-4')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-start w-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_StatsToolbar, {
                    onWeight: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPageShown('Weight'))),
                    onMeasure: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPageShown('Measurements'))),
                    onPrs: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPageShown('PRs')))
                  }),
                  (!_ctx.loginStore.token)
                    ? (_openBlock(), _createBlock(_component_LoginBanner, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "content w-100 ma-0 align-self-stretch"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window, {
                    class: "h-100 d-flex justify-center align-center",
                    modelValue: _ctx.statStore.tab,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statStore.tab) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_window_item, { value: "weight" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_WeightCard)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window_item, { value: "measurement" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MeasurementCard)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window_item, { value: "record" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PRCard)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MyFooter)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}