
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia stores
import { useWindowStore } from '../../../stores/windowStore';
import { useExerciseStore } from '@/stores/exerciseStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import CloseButton from '../../../components/buttons/CloseButton.vue';
// Type interfaces
import { Muscle, Equipment } from '../../../types/index';

export default defineComponent({
    data () {
        return ({
            windowStore: useWindowStore(),
            exerciseStore: useExerciseStore(),
            modeStore: useModeStore(),
            dialog: false,
            mode: 'muscle'
        });
    },
    props: ['muscles', 'equipment'],
    components: {
        CloseButton
    },
    methods: {
		filterMuscle (muscle: Muscle) {
			if (this.exerciseStore.filteredMuscle?.id === muscle.id) {
				this.exerciseStore.filteredMuscle = null;
			} else {
				this.exerciseStore.filteredMuscle = muscle;
			}
		},
		filterEquipment (item: Equipment) {
			if (this.exerciseStore.filteredEquipment?.id === item.id) {
				this.exerciseStore.filteredEquipment = null;
			} else {
				this.exerciseStore.filteredEquipment = item;
			}
		},
        mergeProps
	}
});
