import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event)),
    "max-width": _ctx.windowStore.width >= 600 ? '400px' : '100%'
  }, {
    activator: _withCtx(({ props: dialog }) => [
      _createVNode(_component_v_tooltip, { text: "Add to Workout" }, {
        activator: _withCtx(({ props: tooltip }) => [
          _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps(_ctx.mergeProps(dialog, tooltip))), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-dumbbell",
                size: "xx-large"
              })
            ]),
            _: 2
          }, 1040)
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_v_icon, { icon: "mdi-dumbbell" })
        ]),
        title: _withCtx(() => [
          _createTextVNode(" Add Exercise to Workout ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card_actions, {
            class: "d-flex flex-wrap justify-center align-center w-100",
            style: {gap: '10px'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.workout,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workout) = $event)),
                label: "Select Workout",
                items: _ctx.workoutStore.workouts,
                "item-title": _ctx.mappedNames,
                "no-data-text": "No workouts available",
                "return-object": "",
                class: "w-75"
              }, null, 8, ["modelValue", "items", "item-title"]),
              _createVNode(_component_v_btn, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.workoutStore.updateWorkout(_ctx.workout))),
                variant: "flat",
                color: "success",
                "prepend-icon": "mdi-check"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add to Workout")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialog = false)),
                variant: "flat",
                color: "error",
                "prepend-icon": "mdi-close"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              }),
              _createVNode(_component_CloseButton, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}