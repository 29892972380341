
// Vue imports
import { defineComponent, PropType } from 'vue';
// Type interfaces
import { RoutineData } from '@/types/index';

export default defineComponent({
    props: {
        routine: {
            type: Object as PropType<RoutineData>,
            required: true
        }
    }
});
