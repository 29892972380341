import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-295867b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_RoutineModal = _resolveComponent("RoutineModal")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    extension: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.routineStore.activeDay,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.routineStore.activeDay) = $event)),
        "bg-color": "primary",
        class: _normalizeClass(_ctx.windowStore.width < 992 ? 'w-100' : ''),
        "align-tabs": "center",
        "fixed-tabs": _ctx.windowStore.width < 992,
        "show-arrows": "",
        centered: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routineStore.weekdays, (day, index) => {
            return (_openBlock(), _createBlock(_component_v_tab, {
              key: index,
              value: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon",
                  style: _normalizeStyle({backgroundImage: `url(/images/icons/${day.toLowerCase()}.webp)`})
                }, null, 4),
                (_ctx.windowStore.width >= 1110)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(day), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "class", "fixed-tabs"]),
      (_ctx.windowStore.width >= 992)
        ? (_openBlock(), _createBlock(_component_v_spacer, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_toolbar_items, null, {
        default: _withCtx(() => [
          _createVNode(_component_RoutineModal)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex align-center text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { icon: "mdi-notebook" }),
          _createTextVNode(" My Routines ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}