
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
	data () {
		return ({
			modeStore: useModeStore()
		});
	}
});
