import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_2 = {
  key: 0,
  id: "errorMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dialog) = $event)),
    "max-width": "400px"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        class: "bg-primary",
        variant: "flat",
        style: {minWidth: 'auto', padding: '0 8px'}
      }), {
        default: _withCtx(() => [
          ((_ctx.windowStore.width > 470 && _ctx.windowStore.width <= 992) || _ctx.windowStore.width >= 1100)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Log In"))
            : _createCommentVNode("", true),
          _createVNode(_component_v_icon, {
            icon: "mdi-login",
            size: "large"
          })
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-center text-uppercase pa-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-login-variant",
                size: "xx-large",
                class: "mr-1"
              }),
              _createTextVNode(" Sign In ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, {
            class: "w-100 pa-0",
            style: {minHeight: 0}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tabs, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                "fixed-tabs": "",
                class: "w-100",
                "bg-color": "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tab, {
                    value: "login",
                    "prepend-icon": "mdi-login"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Log In")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_tab, {
                    value: "register",
                    "prepend-icon": "mdi-account-plus",
                    style: {marginInlineStart: 0}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Register")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_CloseButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "d-flex justify-center align-center w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.tab) = $event)),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window_item, {
                    value: "login",
                    class: "w-100"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, { class: "d-flex flex-column justify-center w-100" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: "underlined",
                            "prepend-icon": "mdi-email",
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                            id: "email",
                            name: "email",
                            type: "email",
                            label: "E-Mail",
                            class: "w-100",
                            clearable: ""
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_container, { class: "pa-0 w-100" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: 10 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        variant: "underlined",
                                        "prepend-icon": "mdi-lock",
                                        modelValue: _ctx.password,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                                        id: "password",
                                        name: "password",
                                        type: _ctx.showPassword ? 'text' : 'password',
                                        label: "Password",
                                        clearable: ""
                                      }, null, 8, ["modelValue", "type"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: 2,
                                    class: "d-flex justify-center align-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_btn, {
                                        variant: "plain",
                                        ripple: false,
                                        id: "pw-toggle",
                                        width: "32px",
                                        title: _ctx.showPassword ? 'Hide Password' : 'Show Password',
                                        onClick: _ctx.togglePasswordVisibility
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, {
                                            icon: _ctx.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off'
                                          }, null, 8, ["icon"])
                                        ]),
                                        _: 1
                                      }, 8, ["title", "onClick"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_checkbox, {
                            label: "Stay logged in",
                            modelValue: _ctx.stayLoggedIn,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.stayLoggedIn) = $event)),
                            onChange: _ctx.changeLogPref
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "register" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, { class: "d-flex flex-column justify-center w-100" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: "underlined",
                            "prepend-icon": "mdi-account",
                            modelValue: _ctx.username,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.username) = $event)),
                            id: "username",
                            name: "username",
                            type: "text",
                            label: "Username",
                            clearable: ""
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_text_field, {
                            variant: "underlined",
                            "prepend-icon": "mdi-email",
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
                            id: "email",
                            name: "email",
                            type: "email",
                            label: "E-Mail",
                            clearable: ""
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_container, { class: "d-flex justify-center align-center pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: "underlined",
                                "prepend-icon": "mdi-lock",
                                modelValue: _ctx.password,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.password) = $event)),
                                id: "password",
                                name: "password",
                                type: _ctx.showPassword ? 'text' : 'password',
                                label: "Password",
                                clearable: ""
                              }, null, 8, ["modelValue", "type"]),
                              _createVNode(_component_v_btn, {
                                variant: "plain",
                                ripple: false,
                                id: "pw-toggle",
                                width: "32px",
                                title: _ctx.showPassword ? 'Hide Password' : 'Show Password',
                                onClick: _ctx.togglePasswordVisibility
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, {
                                    icon: _ctx.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off'
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["title", "onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_checkbox, {
                            label: "Stay logged in",
                            modelValue: _ctx.stayLoggedIn,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stayLoggedIn) = $event)),
                            onChange: _ctx.changeLogPref
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "pa-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tab) = $event)),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window_item, { value: "login" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        id: "submit",
                        onClick: _ctx.loginUser,
                        variant: "tonal",
                        class: "bg-primary w-100 rounded-0",
                        size: "x-large",
                        "prepend-icon": "mdi-login"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Log In")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "register" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        id: "submit",
                        onClick: _ctx.registerUser,
                        variant: "tonal",
                        class: "bg-primary w-100 rounded-0",
                        size: "x-large",
                        "prepend-icon": "mdi-account-plus"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Sign Up")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}