
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia imports
import { useRoutineStore } from '@/stores/routineStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
// Type interfaces
import { RoutineData } from '@/types/index';
// Local components
import CloseButton from '../buttons/CloseButton.vue';

export default defineComponent({
    data () {
        return ({
            routine: {} as RoutineData,
            routineStore: useRoutineStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore(),
            snackbarStore: useSnackbarStore(),
            dialog: false
        });
    },
    methods: {
        mappedNames () {
            return this.routineStore.routines.map(function (routine: RoutineData) {
                return routine.attributes.name;
            });
        },
        checkInput () {
            if (!Object.keys(this.routine).length) {
                this.snackbarStore.text = 'No routine selected!';
                this.snackbarStore.color = 'error';
                this.snackbarStore.icon = 'mdi-alert-circle';
                this.snackbarStore.open = true;
            } else {
                this.routineStore.updateRoutine(this.routine);
            }
        },
        checkRoutines () {
            if (!this.routineStore.routines.length) {
                this.snackbarStore.text = 'No routines available!';
                this.snackbarStore.color = 'error';
                this.snackbarStore.icon = 'mdi-alert-circle';
                this.snackbarStore.open = true;
                this.dialog = false;
            }
        },
        mergeProps
    },
    components: {
        CloseButton
    }
})
