import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_banner_text = _resolveComponent("v-banner-text")!
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_v_banner_actions = _resolveComponent("v-banner-actions")!
  const _component_v_banner = _resolveComponent("v-banner")!

  return (_openBlock(), _createBlock(_component_v_banner, {
    icon: "mdi-lock",
    color: _ctx.modeStore.darkMode ? 'white' : 'black',
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-4 px-4 py-2' : 'bg-blue-lighten-3 px-4 py-2')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_banner_text, null, {
        default: _withCtx(() => [
          _createTextVNode("Create an account or log in to safely secure your data and use Gym Tracker from any device!")
        ]),
        _: 1
      }),
      _createVNode(_component_v_banner_actions, { class: "my-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_LoginModal)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color", "class"]))
}