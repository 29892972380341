import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    variant: "plain",
    onClick: _ctx.modeStore.toggleDarkMode,
    style: {minWidth: 'auto', padding: '0'}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        icon: _ctx.modeStore.darkMode ? 'mdi-moon-waning-crescent' : 'mdi-weather-sunny',
        color: _ctx.modeStore.darkMode ? 'white' : 'orange',
        size: "xx-large"
      }, null, 8, ["icon", "color"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}