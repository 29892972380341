
// Vue imports
import { defineComponent } from 'vue';
// Local components
import RoutineModal from '../../components/modals/RoutineModal.vue';
// Pinia stores
import { useRoutineStore } from '@/stores/routineStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            routineStore: useRoutineStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore()
        });
    },
    components: {
        RoutineModal
    }
})
