import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_FilterModal = _resolveComponent("FilterModal")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    extension: _withCtx(() => [
      _createVNode(_component_v_spacer),
      _createVNode(_component_FilterModal, {
        muscles: _ctx.muscles,
        equipment: _ctx.equipment
      }, null, 8, ["muscles", "equipment"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex align-center text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { icon: "mdi-dumbbell" }),
          _createTextVNode(" My Exercises ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}