
// Vue imports
import { defineComponent, ref } from 'vue';
// Pinia stores
import { useStatStore } from '@/stores/statStore';
import { useModeStore } from '@/stores/modeStore';
// Third-party components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// Type interfaces
import { WeightData } from '@/types';
// Local components
import InfoAlert from '../alerts/InfoAlert.vue';

export default defineComponent({
    data () {
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });

        return ({
            statStore: useStatStore(),
            modeStore: useModeStore(),
            month
        });
    },
    components: {
        VueDatePicker,
        InfoAlert
    },
    computed: {
        weights (): WeightData[] {
            return this.statStore.weights.filter(weight => {
                const year = Number(weight.attributes.date.slice(0, 4));
                const month = Number(weight.attributes.date.slice(5, 7)) - 1;

                if (year === this.month.year && month === this.month.month) {
                    return true;
                } else {
                    return false;
                }
            })
        }
    }
});
