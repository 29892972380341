
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia stores
import { useWindowStore } from '../../../stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
import { useStatStore } from '@/stores/statStore';
import { useExerciseStore } from '@/stores/exerciseStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
// Third-party components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';

export default defineComponent({
    data () {
        return ({
            windowStore: useWindowStore(),
            modeStore: useModeStore(),
            statStore: useStatStore(),
            exerciseStore: useExerciseStore(),
            snackbarStore: useSnackbarStore(),
            dialog: false,
            record: 0
        });
    },
    components: {
        VueDatePicker,
		CloseButton
    },
    methods: {
        addRecord () {
			const recordExists = this.statStore.records.find(record => {
				if (record.attributes.date === this.statStore.date.toLocaleDateString('en-CA').split('T')[0] &&
				record.attributes.exercise === this.exerciseStore.exercise.exercise_base) {
					return true;
				} else return false;
			});

			if (recordExists) this.statStore.deleteRecord(recordExists.id);

			this.statStore.addRecord(this.exerciseStore.exercise.exercise_base, this.record);
			this.dialog = false;
			this.snackbarStore.text = 'Record successfully added';
			this.snackbarStore.color = 'success';
			this.snackbarStore.icon = 'mdi-check';
			this.snackbarStore.open = true;
		},
        handleDate (modelData) {
			this.statStore.date = modelData;
			this.statStore.dateVal = this.statStore.date.getDate();
			this.statStore.dateString = modelData.toLocaleDateString('en-CA').split('T')[0];
		},
        mergeProps
    }
});
