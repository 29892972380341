
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useWindowStore } from '@/stores/windowStore';
import { useExerciseStore } from '@/stores/exerciseStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import FilterModal from '@/components/modals/ExercisesView/FilterModal.vue';

export default defineComponent({
    data () {
        return ({
            windowStore: useWindowStore(),
            exerciseStore: useExerciseStore(),
            modeStore: useModeStore()
        });
    },
    props: ['muscles', 'equipment'],
    components: {
        FilterModal
    }
})
