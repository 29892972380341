
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia stores
import { useWindowStore } from '../../../stores/windowStore';
import { useRoutineStore } from '../../../stores/routineStore';
import { useWorkoutStore } from '@/stores/workoutStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { useModeStore } from '@/stores/modeStore';
// Type interfaces
import { Workout } from '../../../types/index';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';

export default defineComponent({
    data () {
        return ({
            windowStore: useWindowStore(),
            routineStore: useRoutineStore(),
			workoutStore: useWorkoutStore(),
			snackbarStore: useSnackbarStore(),
			modeStore: useModeStore(),
            dialog: false,
            routineName: ''
        });
    },
    methods: {
        useRoutine () {
			const routine = this.routineStore.getRoutineByName(this.routineName);

			if (routine) {
				const workout = ({
					date: this.workoutStore.date.toLocaleDateString('en-CA').split('T')[0],
					entries: routine.attributes.entries
				} as Workout);

				this.workoutStore.addWorkout(workout);

				this.dialog = false;
				this.snackbarStore.text = 'Successfully added routine as workout';
				this.snackbarStore.color = 'success';
				this.snackbarStore.icon = 'mdi-check';
				this.snackbarStore.open = true;
			} else {
				this.snackbarStore.text = 'Routine not selected - please select a routine';
				this.snackbarStore.color = 'error';
				this.snackbarStore.icon = 'mdi-alert-circle';
				this.snackbarStore.open = true;
			}
		},
		checkRoutines () {
			if (!this.routineStore.routines.length) {
				this.dialog = false;
				this.snackbarStore.text = 'No routines exist - cannot log a routine';
				this.snackbarStore.color = 'error';
				this.snackbarStore.icon = 'mdi-alert-circle';
				this.snackbarStore.open = true;
			}
		},
		mergeProps
    },
	components: {
		CloseButton
	}
})
