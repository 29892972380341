import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_ExerciseForm = _resolveComponent("ExerciseForm")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    fullscreen: _ctx.windowStore.width < 600,
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog) = $event)),
    "max-width": _ctx.windowStore.width >= 600 ? '400px' : '100%'
  }, {
    activator: _withCtx(({ props: dialog }) => [
      _createVNode(_component_v_tooltip, {
        text: "Add New Workout",
        "open-delay": 125
      }, {
        activator: _withCtx(({ props: tooltip }) => [
          _createVNode(_component_v_btn, _mergeProps(_ctx.mergeProps(dialog, tooltip), { onClick: _ctx.checkWorkouts }), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-plus",
                size: "xx-large"
              })
            ]),
            _: 2
          }, 1040, ["onClick"])
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      (_ctx.exerciseQuery.isSuccess)
        ? (_openBlock(), _createBlock(_component_v_card, {
            key: 0,
            class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center rounded-lg h-100' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center rounded-lg h-100')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "text-uppercase text-wrap text-center w-75" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi-dumbbell",
                    size: "xx-large"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.workoutStore.days[_ctx.workoutStore.date.getDay()]) + ", " + _toDisplayString(_ctx.workoutStore.months[_ctx.workoutStore.date.getMonth()]) + " " + _toDisplayString(_ctx.workoutStore.date.getDate()) + ", " + _toDisplayString(_ctx.workoutStore.date.getFullYear()), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, { class: "w-100 d-flex justify-center align-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tabs, {
                    "bg-color": "primary",
                    "show-arrows": "",
                    "fixed-tabs": "",
                    centered: "",
                    density: "compact",
                    modelValue: _ctx.workoutStore.tab,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workoutStore.tab) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workoutStore.entries, (entry, index) => {
                        return (_openBlock(), _createBlock(_component_v_tab, {
                          key: index,
                          value: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Exercise " + _toDisplayString(index + 1), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_v_tooltip, {
                    text: "Add Exercise",
                    "open-delay": 125
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps(props, {
                        class: "bg-primary rounded-0",
                        onClick: _ctx.workoutStore.addEntry
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-plus",
                            size: "xx-large"
                          })
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_CloseButton, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "w-100 pa-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window, {
                    modelValue: _ctx.workoutStore.tab,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.workoutStore.tab) = $event)),
                    class: "bg-blue-grey-darken-4 pa-3 h-100"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workoutStore.entries, (entry, index) => {
                        return (_openBlock(), _createBlock(_component_v_window_item, {
                          key: index,
                          value: index
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ExerciseForm, {
                              entry: entry,
                              modal: "workout",
                              exercises: _ctx.filteredExercises
                            }, null, 8, ["entry", "exercises"])
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.addWorkout,
                    class: "bg-primary",
                    "prepend-icon": "mdi-plus"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Add Workout ")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["fullscreen", "modelValue", "max-width"]))
}