
// Vue imports
import { defineComponent, PropType } from 'vue';
// Pinia stores
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            modeStore: useModeStore()
        });
    },
    props: {
        title: {
            type: String as PropType<string>,
            required: true
        },
        text: {
            type: String as PropType<string>,
            required: true
        }
    }
})
