import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardToolbar = _resolveComponent("DashboardToolbar")!
  const _component_LoginBanner = _resolveComponent("LoginBanner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_WorkoutCard = _resolveComponent("WorkoutCard")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_RoutinePreview = _resolveComponent("RoutinePreview")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_InfoAlert = _resolveComponent("InfoAlert")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-grey-darken-4' : 'bg-blue-lighten-4')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-start w-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_DashboardToolbar),
                  (!_ctx.loginStore.token)
                    ? (_openBlock(), _createBlock(_component_LoginBanner, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.workoutStore.activeWorkouts.length)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "content w-100 ma-0 align-self-stretch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "mx-auto bg-grey-darken-4",
                        elevation: "8",
                        "max-width": "800",
                        height: 400
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "flex text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Today's Workouts "),
                              _createVNode(_component_v_icon, {
                                icon: "mdi-weight-lifter",
                                size: "large"
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, {
                            class: "mx-auto bg-grey-darken-4 d-flex justify-center align-start",
                            elevation: "8",
                            "max-width": "800",
                            height: 400
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_window, {
                                modelValue: _ctx.window,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.window) = $event)),
                                "show-arrows": "",
                                height: 400,
                                class: "d-flex justify-center align-start"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workoutStore.activeWorkouts, (workout) => {
                                    return (_openBlock(), _createBlock(_component_v_window_item, {
                                      key: workout.id,
                                      class: "mx-auto",
                                      height: 400
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_WorkoutCard, {
                                          class: "overflow-y-auto",
                                          workout: workout,
                                          preview: true,
                                          height: 325
                                        }, null, 8, ["workout"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.routineStore.activeDayRoutines.length)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 1,
                "no-gutters": "",
                class: "content w-100 ma-0 align-self-stretch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_subheader, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Today's Routines")
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routineStore.activeDayRoutines, (routine) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: routine.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_RoutinePreview, { routine: routine }, null, 8, ["routine"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.workoutStore.activeWorkouts.length && !_ctx.routineStore.activeDayRoutines.length)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 2,
                "no-gutters": "",
                class: "content w-100 ma-0 align-self-stretch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, { class: "h-100" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "h-100" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: 12,
                                class: "d-flex justify-center align-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_InfoAlert, {
                                    title: "No Workouts or Routines",
                                    text: "No workouts logged today or routines scheduled for today."
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MyFooter)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}