
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useLoginStore } from '@/stores/loginStore';
import { useWindowStore } from '@/stores/windowStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import CloseButton from '@/components/buttons/CloseButton.vue';
// Third-party libraries
import Cookies from 'js-cookie';

export default defineComponent({
	components: {
		CloseButton
	},
	data () {
		const username = '';
		const email = '';
		const password = '';
		const errorMessage = '';
		const loginStore = useLoginStore();
		const windowStore = useWindowStore();
		const snackbarStore = useSnackbarStore();
		const modeStore = useModeStore();
		const showPassword = false;
		const token = Cookies.get('token');
		const stayLoggedIn = (Cookies.get('stayLoggedIn') === 'true');

		return ({
			username,
			email,
			password,
			errorMessage,
			error: false,
			loginStore,
			windowStore,
			snackbarStore,
			modeStore,
			showPassword,
			dialog: false,
			token,
			stayLoggedIn,
			tab: 'login'
		});
	},
	methods: {
		setError () {
			this.error = true;
		},
		togglePasswordVisibility (e: MouseEvent) {
			e.preventDefault();
			this.showPassword = !this.showPassword;
		},
		loginUser () {
			this.loginStore.loginUser(this.email, this.password);

			if (!this.loginStore.error) {
				this.snackbarStore.text = `Welcome back, ${this.email}!`;
				this.snackbarStore.color = 'success';
				this.snackbarStore.icon = 'mdi-check-circle';
			} else {
				this.snackbarStore.text = 'Failed to log in';
				this.snackbarStore.color = 'error';
				this.snackbarStore.icon = 'mdi-alert-circle';
			}

			this.dialog = false;
			this.snackbarStore.open = true;
		},
		registerUser () {
			this.loginStore.registerUser(this.username, this.email, this.password);

			if (!this.loginStore.error) {
				this.snackbarStore.text = `Welcome, ${this.username}!`;
				this.snackbarStore.color = 'success';
				this.snackbarStore.icon = 'mdi-check-circle';
			} else {
				this.snackbarStore.text = 'Failed to register new user';
				this.snackbarStore.color = 'error';
				this.snackbarStore.icon = 'mdi-alert-circle';
			}

			this.dialog = false;
			this.snackbarStore.open = true;
		},
		changeLogPref () {
			if (this.stayLoggedIn) {
				Cookies.set('stayLoggedIn', true);
			} else {
				Cookies.set('stayLoggedIn', false);
			}
		}
	}
});
