import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dialog) = $event)),
    class: "StatsModal w-100 h-100",
    "max-width": _ctx.windowStore.width >= 600 ? '400px' : '100%'
  }, {
    activator: _withCtx(({ props: dialog }) => [
      _createVNode(_component_v_tooltip, {
        text: "Add New Stat",
        "open-delay": 125
      }, {
        activator: _withCtx(({ props: tooltip }) => [
          _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps(_ctx.mergeProps(dialog, tooltip))), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-plus",
                size: "xx-large"
              })
            ]),
            _: 2
          }, 1040)
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-center align-center w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-account-search",
                class: "mr-1"
              }),
              _createTextVNode(" Log Statistic ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, {
            class: "d-flex flex-wrap justify-center align-center w-100",
            style: {gap: '10px'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VueDatePicker, {
                class: "d-flex justify-center align-center",
                dark: _ctx.modeStore.darkMode ? true : false,
                modelValue: _ctx.statStore.date,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statStore.date) = $event)),
                  _ctx.handleDate
                ],
                ref: "datepicker",
                teleport: true,
                "enable-time-picker": false
              }, null, 8, ["dark", "modelValue", "onUpdate:modelValue"]),
              _createVNode(_component_v_tabs, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tab) = $event)),
                "bg-color": "primary",
                "show-arrows": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tab, {
                    value: "weight",
                    "prepend-icon": "mdi-scale-bathroom",
                    stacked: _ctx.windowStore.width < 600,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('weight')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Weight")
                    ]),
                    _: 1
                  }, 8, ["stacked"]),
                  _createVNode(_component_v_tab, {
                    value: "measurement",
                    "prepend-icon": "mdi-tape-measure",
                    stacked: _ctx.windowStore.width < 600,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('measure')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Measurements")
                    ]),
                    _: 1
                  }, 8, ["stacked"]),
                  _createVNode(_component_v_tab, {
                    value: "record",
                    "prepend-icon": "mdi-medal",
                    stacked: _ctx.windowStore.width < 600,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('prs')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("1-Rep Max")
                    ]),
                    _: 1
                  }, 8, ["stacked"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_CloseButton, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          }),
          (_ctx.isSuccess && _ctx.data)
            ? (_openBlock(), _createBlock(_component_v_card_text, {
                key: 0,
                class: "d-flex justify-center align-center w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window, {
                    modelValue: _ctx.tab,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.tab) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_window_item, { value: "weight" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_responsive, {
                            class: "mx-auto",
                            width: "144"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.weight,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.weight) = $event)),
                                "hide-details": "auto",
                                density: "compact",
                                type: "number",
                                label: "Weight (lbs)",
                                "append-icon": "mdi-weight-pound"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window_item, { value: "measurement" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_responsive, {
                            class: "mx-auto",
                            width: "256"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.muscle,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.muscle) = $event)),
                                label: "Muscle",
                                items: ['Upper Arm', 'Forearm', 'Chest', 'Thigh', 'Calf', 'Waist', 'Shoulder'],
                                "append-icon": "mdi-arm-flex"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.measurement,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.measurement) = $event)),
                                "hide-details": "auto",
                                density: "compact",
                                type: "number",
                                label: "Measurement (in)",
                                class: "w-100",
                                "append-icon": "mdi-tape-measure",
                                min: 1
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_window_item, { value: "record" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_responsive, {
                            class: "mx-auto",
                            width: "320"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.exercise,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.exercise) = $event)),
                                label: "Exercise",
                                placeholder: _ctx.data.results[0].name,
                                items: _ctx.data.results.map(exercise => exercise.name),
                                "append-icon": "mdi-dumbbell"
                              }, null, 8, ["modelValue", "placeholder", "items"]),
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.record,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.record) = $event)),
                                "hide-details": "auto",
                                density: "compact",
                                type: "number",
                                label: "1-Rep Max (lbs)",
                                class: "w-100",
                                "append-icon": "mdi-medal"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _ctx.addStat,
                variant: "flat",
                color: "success",
                "prepend-icon": "mdi-plus"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.dialog = false)),
                variant: "flat",
                color: "error",
                "prepend-icon": "mdi-close"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}