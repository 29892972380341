import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileLayout = _resolveComponent("MobileLayout")!
  const _component_TabletLayout = _resolveComponent("TabletLayout")!
  const _component_DesktopLayout = _resolveComponent("DesktopLayout")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.windowStore.width < 600)
        ? (_openBlock(), _createBlock(_component_MobileLayout, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.windowStore.width >= 600 && _ctx.windowStore.width < 992)
        ? (_openBlock(), _createBlock(_component_TabletLayout, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.windowStore.width >= 992)
        ? (_openBlock(), _createBlock(_component_DesktopLayout, { key: 2 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_snackbar, {
        top: "",
        modelValue: _ctx.snackbarStore.open,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbarStore.open) = $event)),
        timeout: 2000,
        color: _ctx.snackbarStore.color
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            flat: "",
            color: "white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snackbarStore.open = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Close")
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            icon: _ctx.snackbarStore.icon
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.snackbarStore.text) + " ", 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}