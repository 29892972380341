
// Vue imports
import { defineComponent } from 'vue';
// Local components
import ModeSlider from '../buttons/ModeButton/ModeSlider.vue';
import LoginModal from '../modals/LoginModal.vue';
import LogoutModal from '../modals/LogoutModal.vue';
// Pinia stores
import { useLoginStore } from '@/stores/loginStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
// Third-party libraries
import Cookies from 'js-cookie';

export default defineComponent({
    data () {
        return ({
            loginStore: useLoginStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore(),
            token: Cookies.get('token')
        });
    },
    components: {
        ModeSlider,
        LoginModal,
        LogoutModal
    }
});
