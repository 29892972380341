
// Vue imports
import { defineComponent, PropType } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Type interfaces
import { Muscle, Equipment, Exercise, ImageData } from '@/types/index';
// Mixins
import { getCorrectName } from '@/mixins/getCorrectName';
import { getSlug } from '@/mixins/getSlug';
// Pinia stores
import { useModeStore } from '@/stores/modeStore';

const getImageData = async (base: number): Promise<ImageData> => {
	return await fetch(`https://wger.de/api/v2/exerciseimage/?limit=999&exercise_base=${base}`)
		.then(res => res.json())
		.catch(err => console.log(err));
}

export default defineComponent({
	data () {
		const images = useQuery(['images', this.exercise.exercise_base], () => getImageData(this.exercise.exercise_base));

		return ({
			images,
			modeStore: useModeStore()
		});
	},
	props: {
		exercise: {
			type: Object as PropType<Exercise>,
			required: true
		},
		muscles: {
			type: Object as PropType<Muscle[]>,
			required: true
		},
		equipment: {
			type: Object as PropType<Equipment[]>,
			required: true
		}
	},
	mixins: [getCorrectName, getSlug],
	methods: {
		getMuscleName (item: number) {
			const muscle = this.muscles.find((muscle: Muscle) => muscle.id === item);

			if (!muscle) return '';
				else if (muscle.name_en) {
					return muscle.name_en.split(' ')[0];
				} else return muscle.name.split(' ')[0];
		},
		getEquipmentName (item: number) {
			const piece = this.equipment.find((piece: Equipment) => piece.id === item);

			if (!piece) return '';
				else return piece.name;
		},
		getLocalImage (folder: string, name: string) {
			return ({
				backgroundImage: `url('/images/${folder}/${this.getSlug(name)}.webp')`,
				backgroundSize: 'contain',
				backgroundPosition: 'center',
				width: '32px',
				height: '32px',
				display: 'inline-block',
				filter: this.modeStore.darkMode ? 'invert(1)' : ''
			});
		}
	},
	computed: {
		name () {
			return this.getCorrectName(this.exercise.name);
		}
	}
});
