
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useLoginStore } from './stores/loginStore';
// Local components
import MobileLayout from './components/layouts/MobileLayout.vue';
import TabletLayout from './components/layouts/TabletLayout.vue';
import DesktopLayout from './components/layouts/DesktopLayout.vue';
// Pinia stores
import { useExerciseStore } from './stores/exerciseStore';
import { useRoutineStore } from '@/stores/routineStore';
import { useStatStore } from './stores/statStore';
import { useWorkoutStore } from './stores/workoutStore';
import { useWindowStore } from './stores/windowStore';
import { useSnackbarStore } from './stores/snackbarStore';

export default defineComponent({
	name: 'App',
	components: {
		MobileLayout,
		TabletLayout,
		DesktopLayout
	},
	data () {
		const exerciseStore = useExerciseStore();
		const routineStore = useRoutineStore();
		const statStore = useStatStore();
		const workoutStore = useWorkoutStore();
		const loginStore = useLoginStore();
		const windowStore = useWindowStore();
		const snackbarStore = useSnackbarStore();

		return ({
			exerciseStore,
			routineStore,
			statStore,
			workoutStore,
			loginStore,
			windowStore,
			snackbarStore
		});
	},
	mounted () {
		this.$nextTick(() => {
			window.addEventListener('resize', this.windowStore.resize);
		});
	},
	created () {
		this.exerciseStore.getFavorites();
		this.routineStore.getRoutineData();
		this.statStore.getStats();
		this.workoutStore.getWorkouts();
	}
});
