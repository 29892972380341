import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event)),
    class: "w-100 h-100",
    "max-width": _ctx.windowStore.width >= 600 ? '400px' : '100%'
  }, {
    activator: _withCtx(({ props: dialog }) => [
      _createVNode(_component_v_tooltip, {
        text: "Add New Record",
        "open-delay": 125
      }, {
        activator: _withCtx(({ props: tooltip }) => [
          _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps(_ctx.mergeProps(dialog, tooltip))), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-medal",
                size: "xx-large"
              })
            ]),
            _: 2
          }, 1040)
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-center align-center w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-medal",
                class: "mr-1"
              }),
              _createTextVNode(" Log 1-Rep Max ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, {
            class: "d-flex flex-wrap justify-center align-center w-100",
            style: {gap: '10px'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VueDatePicker, {
                class: "d-flex justify-center align-center",
                dark: _ctx.modeStore.darkMode ? true : false,
                modelValue: _ctx.statStore.date,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statStore.date) = $event)),
                  _ctx.handleDate
                ],
                ref: "datepicker",
                teleport: true,
                "enable-time-picker": false
              }, null, 8, ["dark", "modelValue", "onUpdate:modelValue"]),
              _createVNode(_component_CloseButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "d-flex flex-column justify-center align-center w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                class: "w-100",
                value: _ctx.exerciseStore.exercise.name,
                "append-icon": "mdi-dumbbell",
                readonly: ""
              }, null, 8, ["value"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.record,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.record) = $event)),
                "hide-details": "auto",
                density: "compact",
                type: "number",
                label: "1-Rep Max (lbs)",
                class: "w-100",
                "append-icon": "mdi-medal"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _ctx.addRecord,
                variant: "flat",
                color: "success",
                "prepend-icon": "mdi-plus"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false)),
                variant: "flat",
                color: "error",
                "prepend-icon": "mdi-close"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}