import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExercisesToolbar = _resolveComponent("ExercisesToolbar")!
  const _component_LoginBanner = _resolveComponent("LoginBanner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_ExerciseCard = _resolveComponent("ExerciseCard")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-grey-darken-4' : 'bg-blue-lighten-4')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-start w-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ExercisesToolbar, {
                    muscles: _ctx.muscles,
                    equipment: _ctx.equipment
                  }, null, 8, ["muscles", "equipment"]),
                  (!_ctx.loginStore.token)
                    ? (_openBlock(), _createBlock(_component_LoginBanner, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "content w-100 ma-0 align-self-stretch"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, {
                        "no-gutters": "",
                        class: "w-100 ma-0 align-self-stretch"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: 12 }, {
                            default: _withCtx(() => [
                              (_ctx.exercises.isSuccess && _ctx.exercises.data)
                                ? (_openBlock(), _createBlock(_component_v_pagination, {
                                    key: 0,
                                    modelValue: _ctx.exerciseStore.page,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exerciseStore.page) = $event)),
                                    length: Math.ceil(_ctx.exercises.data.count / 20),
                                    rounded: "circle"
                                  }, null, 8, ["modelValue", "length"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, {
                        "no-gutters": "",
                        class: "w-100 ma-0 align-self-stretch"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.error)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 0,
                                cols: 12
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_alert, {
                                    "max-width": 650,
                                    border: "start",
                                    "border-color": "red-accent-1",
                                    elevation: "2",
                                    type: "error",
                                    title: "Error",
                                    text: "There is an issue communicating with the server. Please try again later."
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          ((_ctx.exercises.isLoading || _ctx.muscles.isLoading || _ctx.equipment.isLoading) && !_ctx.error)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 1,
                                cols: 12,
                                class: "d-flex justify-center align-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_progress_circular, {
                                    indeterminate: "",
                                    size: 70,
                                    width: 7,
                                    color: "primary"
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      ((_ctx.exercises.isSuccess && _ctx.exercises.data && _ctx.muscles.isSuccess && _ctx.muscles.data && _ctx.equipment.isSuccess && _ctx.equipment.data) && !_ctx.error)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "w-100 ma-0 align-self-stretch"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredExercises, (exercise) => {
                                return (_openBlock(), _createBlock(_component_v_col, {
                                  key: exercise.id,
                                  xs: 12,
                                  sm: 6,
                                  md: 4,
                                  lg: 3,
                                  xl: 3,
                                  xxl: 3,
                                  class: "mx-auto d-flex justify-center align-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ExerciseCard, {
                                      exercise: exercise,
                                      muscles: _ctx.muscles.data.results,
                                      equipment: _ctx.equipment.data.results
                                    }, null, 8, ["exercise", "muscles", "equipment"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_row, {
                        "no-gutters": "",
                        class: "w-100 ma-0 align-self-stretch"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: 12 }, {
                            default: _withCtx(() => [
                              (_ctx.exercises.isSuccess && _ctx.exercises.data)
                                ? (_openBlock(), _createBlock(_component_v_pagination, {
                                    key: 0,
                                    modelValue: _ctx.exerciseStore.page,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exerciseStore.page) = $event)),
                                    length: Math.ceil(_ctx.exercises.data.count / 20),
                                    "total-visible": 7,
                                    rounded: "circle"
                                  }, null, 8, ["modelValue", "length"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MyFooter)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}