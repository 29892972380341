import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoutineToolbar = _resolveComponent("RoutineToolbar")!
  const _component_LoginBanner = _resolveComponent("LoginBanner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_WorkoutCard = _resolveComponent("WorkoutCard")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_InfoAlert = _resolveComponent("InfoAlert")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_MyFooter = _resolveComponent("MyFooter")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-grey-darken-4' : 'bg-blue-lighten-4')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-start w-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_RoutineToolbar),
                  (!_ctx.loginStore.token)
                    ? (_openBlock(), _createBlock(_component_LoginBanner, { key: 0 }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "content w-100 ma-0 align-self-stretch" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window, {
                    modelValue: _ctx.routineStore.activeDay,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.routineStore.activeDay) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routineStore.weekdays, (day, index) => {
                        return (_openBlock(), _createBlock(_component_v_window_item, {
                          key: index,
                          value: index,
                          class: "mx-auto pa-2"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.routineStore.getRoutinesByDay(day).length)
                              ? (_openBlock(), _createBlock(_component_v_list, {
                                  key: 0,
                                  class: "d-flex flex-wrap justify-center align-center bg-transparent"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routineStore.getRoutinesByDay(day), (routine) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: routine.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_WorkoutCard, {
                                            routine: routine,
                                            preview: false
                                          }, null, 8, ["routine"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_InfoAlert, {
                                  key: 1,
                                  title: "No Routines",
                                  text: `You have no routines for ${day}.`
                                }, null, 8, ["text"]))
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "ma-0 align-self-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_MyFooter)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}