import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    "max-width": 650,
    border: "start",
    elevation: "2",
    type: "info",
    title: _ctx.title,
    text: _ctx.text,
    variant: _ctx.modeStore.darkMode ? 'tonal' : 'flat'
  }, null, 8, ["title", "text", "variant"]))
}