import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_AddStatModal = _resolveComponent("AddStatModal")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    extension: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.statStore.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statStore.tab) = $event)),
        "bg-color": "primary",
        class: _normalizeClass(_ctx.windowStore.width < 992 ? 'w-100' : ''),
        "align-tabs": "center",
        "fixed-tabs": _ctx.windowStore.width < 992,
        "show-arrows": "",
        centered: "",
        stacked: _ctx.windowStore.width < 992
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, { value: "weight" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "mdi-scale-bathroom" }),
              (_ctx.windowStore.width >= 600)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Weight"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "measurement" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "mdi-tape-measure" }),
              (_ctx.windowStore.width >= 600)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Measurements"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tab, { value: "record" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "mdi-medal" }),
              (_ctx.windowStore.width >= 600)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "1-Rep Max"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "class", "fixed-tabs", "stacked"]),
      (_ctx.windowStore.width >= 992)
        ? (_openBlock(), _createBlock(_component_v_spacer, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_toolbar_items, null, {
        default: _withCtx(() => [
          _createVNode(_component_AddStatModal, {
            onWeight: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('weight'))),
            onMeasure: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('measure'))),
            onPrs: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('prs'))),
            onShowSnackBar: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('showSnackBar')))
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex align-center text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { icon: "mdi-account-search" }),
          _createTextVNode(" My Stats ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}