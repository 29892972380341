
// Vue imports
import { defineComponent } from 'vue';
// Pinia stores
import { useLoginStore } from '@/stores/loginStore';
import { useWindowStore } from '@/stores/windowStore';
// Local components
import ModeButton from '../buttons/ModeButton/ModeButton.vue';
import ModeSlider from '../buttons/ModeButton/ModeSlider.vue';
import LoginModal from '../modals/LoginModal.vue';
import WarningModal from '../modals/WarningModal.vue';

export default defineComponent({
	data () {
		return ({
			loginStore: useLoginStore(),
			windowStore: useWindowStore()
		});
	},
	components: {
		ModeButton,
		ModeSlider,
		LoginModal,
		WarningModal
	}
});
