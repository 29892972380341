
// Vue imports
import { defineComponent } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Pinia stores
import { useExerciseStore } from '@/stores/exerciseStore';
import { useWindowStore } from '@/stores/windowStore';
import { useLoginStore } from '@/stores/loginStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import ExerciseCard from '@/components/cards/ExerciseCard.vue';
import LoginBanner from '@/components/banners/LoginBanner.vue';
import ExercisesToolbar from '@/components/toolbars/ExercisesToolbar.vue';
import MyFooter from '@/components/MyFooter.vue';
// Type interfaces
import { ExerciseData, Exercise, Muscle, Equipment } from '@/types/index';

const getExerciseData = async (page: number, muscle: Muscle | null, equipment: Equipment | null): Promise<ExerciseData> => {
	let url = `https://wger.de/api/v2/exercise/?language=2&limit=20&offset=${(page - 1) * 20}`;

	if (muscle) {
		url = url + `&muscles=${muscle.id}`;
	}

	if (equipment) {
		url = url + `&equipment=${equipment.id}`;
	}

	return await fetch(url)
		.then(res => res.json())
		.catch(err => console.log(err));
}

const getData = async (url: string): Promise<any> => {
	return await fetch(url)
		.then(res => res.json())
		.catch(err => console.log(err));
}

export default defineComponent({
	data () {
		const exerciseStore = useExerciseStore();
		const exercises = useQuery('exercises', () => getExerciseData(exerciseStore.page, exerciseStore.filteredMuscle, exerciseStore.filteredEquipment), { onError: (error) => console.log('ERROR', error) });
		const muscles = useQuery('muscles', () => getData('https://wger.de/api/v2/muscle?limit=999'), { useErrorBoundary: true });
		const equipment = useQuery('equipment', () => getData('https://wger.de/api/v2/equipment?limit=999'), { useErrorBoundary: true });

		return ({
			exerciseStore,
			windowStore: useWindowStore(),
			loginStore: useLoginStore(),
			modeStore: useModeStore(),
			exercises,
			muscles,
			equipment
		});
	},
	watch: {
		'exerciseStore.page' () {
			this.exercises.refetch();
			this.$nextTick(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			});
		}
	},
	components: {
		ExerciseCard,
		LoginBanner,
		ExercisesToolbar,
		MyFooter
	},
	computed: {
		error () {
			if (this.exercises.error ||
			this.exercises.isError ||
			this.exercises.isLoadingError ||
			(this.exercises.isSuccess && !this.exercises.data) ||
			this.muscles.error ||
			this.muscles.isError ||
			this.muscles.isLoadingError ||
			(this.muscles.isSuccess && !this.muscles.data) ||
			this.equipment.error ||
			this.equipment.isError ||
			this.equipment.isLoadingError ||
			(this.equipment.isSuccess && !this.equipment.data) ||
			(!this.exercises.isLoading && !this.exercises.isSuccess) ||
			(!this.muscles.isLoading && !this.muscles.isSuccess) ||
			(!this.equipment.isLoading && !this.equipment.isSuccess)) {
				return true;
			} else {
				return false;
			}
		},
		filteredExercises (): Exercise[] {
			if (this.exercises.data) {
				const filtered = Array.from(this.exercises.data.results);

				for (let i = 0; i < filtered.length - 1; i++) {
					if (filtered[i].name === filtered[i + 1].name) {
						filtered.splice(i + 1, 1);
					}
				}

				return filtered;
			} else {
				return [];
			}
		}
	}
});
