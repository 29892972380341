
// Vue imports
import { defineComponent, mergeProps } from 'vue';
// Pinia imports
import { useWorkoutStore } from '@/stores/workoutStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';
// Type interfaces
import { WorkoutData } from '@/types/index';
// Local components
import CloseButton from '../buttons/CloseButton.vue';

export default defineComponent({
    data () {
        return ({
            workout: {} as WorkoutData,
            workoutStore: useWorkoutStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore(),
            dialog: false
        });
    },
    methods: {
        mappedNames () {
            return this.workoutStore.workouts.map(function (workout: WorkoutData) {
                return workout.attributes.date;
            });
        },
        mergeProps
    },
    components: {
        CloseButton
    }
})
