
// Vue imports
import { defineComponent } from 'vue';
// Local components
import LoginModal from '../../components/modals/LoginModal.vue';
// Pinia stores
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            modeStore: useModeStore()
        });
    },
    components: {
        LoginModal
    }
});
