<template>
<v-btn
    class="DeleteButton"
    variant="plain"
>
    <v-icon
        icon="mdi-delete"
        size="xx-large"
    ></v-icon>
</v-btn>
</template>
