
// Vue imports
import { defineComponent } from 'vue';
// Vue Query imports
import { useQuery } from 'vue-query';
// Pinia stores
import { useStatStore } from '@/stores/statStore';
import { useModeStore } from '@/stores/modeStore';
// Type interfaces
import { ExerciseData, Exercise } from '@/types/index';
// Local components
import InfoAlert from '../alerts/InfoAlert.vue';

const getData = async (url: string): Promise<ExerciseData> => {
	return await fetch(url)
		.then(res => res.json())
		.catch(err => console.log(err));
}

export default defineComponent({
    data () {
		const { error, isError, isLoading, isFetching, isSuccess, data, refetch } = useQuery('exercises', () => getData('https://wger.de/api/v2/exercise/?language=2&limit=999'));

        return ({
            statStore: useStatStore(),
            modeStore: useModeStore(),
            exercise: '',
            error,
            isError,
            isLoading,
            isFetching,
            isSuccess,
            data,
            refetch
        });
    },
    methods: {
        filteredRecords () {
			const exercise = this.data?.results.find(exercise => exercise.name === this.exercise);
			if (!exercise) return;

            return this.statStore.records.filter(record => record.attributes.exercise === exercise.exercise_base);
        }
    },
    components: {
        InfoAlert
    },
    computed: {
        filteredExercises (): Exercise[] {
			if (this.data) {
				const filtered = Array.from(this.data.results);

				for (let i = 0; i < filtered.length - 1; i++) {
					if (filtered[i].name === filtered[i + 1].name) {
						filtered.splice(i + 1, 1);
					}
				}

				return filtered;
			} else {
				return [];
			}
		}
    }
});
