
// Vue imports
import { defineComponent, PropType } from 'vue';
// Type interfaces
import { Exercise, Entry } from '@/types/index';
// Pinia stores
import { useWorkoutStore } from '@/stores/workoutStore';
import { useRoutineStore } from '@/stores/routineStore';
import { useModeStore } from '@/stores/modeStore';
// Local components
import SetForm from '@/components/modals/WorkoutModal/SetForm.vue';
import DeleteButton from '@/components/buttons/DeleteButton.vue';

export default defineComponent({
    data () {
        const tickLabels = ({
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6'
        });

        return ({
            workoutStore: useWorkoutStore(),
            routineStore: useRoutineStore(),
            modeStore: useModeStore(),
            name: '2 Handed Kettlebell Swing',
            sets: 1,
            tickLabels
        });
    },
    props: {
        exercises: {
            type: Array as PropType<Exercise[]>,
            required: true
        },
        entry: {
            type: Object as PropType<Entry>,
            required: true
        },
        modal: {
            type: String as PropType<string>,
            required: true
        }
    },
    components: {
        SetForm,
        DeleteButton
    },
    methods: {
        updateName (name: string) {
            if (this.modal === 'workout') {
                this.workoutStore.entries[this.entry.key].name = name;
            } else {
                this.routineStore.entries[this.entry.key].name = name;
            }

            this.name = name;
        },
        updateSetCount (oldCount: number, newCount: number) {
            if (this.sets > newCount) {
                if (this.modal === 'workout') {
                    this.workoutStore.entries[this.entry.key].sets.slice(0, newCount);
                } else {
                    this.routineStore.entries[this.entry.key].sets.slice(0, newCount);
                }
            } else {
                for (let i = this.sets; i < newCount; i++) {
                    if (this.modal === 'workout') {
                        this.workoutStore.entries[this.entry.key].sets.push({
                            key: i,
                            reps: 1,
                            weight: 0
                        });
                    } else {
                        this.routineStore.entries[this.entry.key].sets.push({
                            key: i,
                            reps: 1,
                            weight: 0
                        });
                    }
                }
            }

            this.sets = newCount;
        }
    }
})
