import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
    "max-width": "400px"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps(props, {
        class: "bg-primary",
        variant: "flat",
        style: {minWidth: 'auto', padding: '0 8px'}
      }), {
        default: _withCtx(() => [
          ((_ctx.windowStore.width > 470 && _ctx.windowStore.width <= 992) || _ctx.windowStore.width >= 1100)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Log Out"))
            : _createCommentVNode("", true),
          _createVNode(_component_v_icon, {
            icon: "mdi-logout",
            size: "large"
          })
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode("Are you sure you want to log out?")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                "prepend-icon": "mdi-check",
                class: "bg-success",
                onClick: _ctx.logout
              }, {
                default: _withCtx(() => [
                  _createTextVNode("YES")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                "prepend-icon": "mdi-close",
                class: "bg-error",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("NO")
                ]),
                _: 1
              }),
              _createVNode(_component_CloseButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}