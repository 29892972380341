
// Vue imports
import { defineComponent } from 'vue';
// Local components
import AddStatModal from '../modals/StatsView/AddStatModal.vue';
// Pinia stores
import { useWindowStore } from '@/stores/windowStore';
import { useStatStore } from '@/stores/statStore';
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            windowStore: useWindowStore(),
            statStore: useStatStore(),
            modeStore: useModeStore()
        });
    },
    components: {
        AddStatModal
    }
})
