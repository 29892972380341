
// Vue imports
import { defineComponent } from 'vue';
// Local components
import CloseButton from '../buttons/CloseButton.vue';
// Pinia stores
import { useLoginStore } from '@/stores/loginStore';
import { useSnackbarStore } from '@/stores/snackbarStore';
import { useWindowStore } from '@/stores/windowStore';
import { useModeStore } from '@/stores/modeStore';

export default defineComponent({
    data () {
        return ({
            loginStore: useLoginStore(),
            snackbarStore: useSnackbarStore(),
            windowStore: useWindowStore(),
            modeStore: useModeStore(),
            dialog: false
        });
    },
    components: {
        CloseButton
    },
    methods: {
        logout () {
            this.loginStore.token = null;
            this.loginStore.removeToken();
            this.dialog = false;

            this.snackbarStore.text = 'Successfully logged out';
            this.snackbarStore.color = 'success';
            this.snackbarStore.icon = 'mdi-check-circle';
            this.snackbarStore.open = true;
        }
    }
});
