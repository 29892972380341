import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.routine)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.routine.attributes.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (_ctx.routine.attributes.entries)
                ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routine.attributes.entries, (entry) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          key: entry.key
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(entry.name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                (entry.sets)
                                  ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list, null, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.sets, (set) => {
                                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                                key: set.key
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("span", null, _toDisplayString(set.reps) + " reps", 1),
                                                  _createElementVNode("span", null, _toDisplayString(set.weight) + " lbs.", 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}