import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    scrollable: "",
    persistent: "",
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
    "max-width": _ctx.windowStore.width >= 600 ? '400px' : '100%'
  }, {
    activator: _withCtx(({ props: dialog }) => [
      _createVNode(_component_v_tooltip, {
        text: "Add Routine as Workout",
        "open-delay": 125
      }, {
        activator: _withCtx(({ props: tooltip }) => [
          _createVNode(_component_v_btn, _mergeProps(_ctx.mergeProps(dialog, tooltip), { onClick: _ctx.checkRoutines }), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-notebook",
                size: "xx-large"
              })
            ]),
            _: 2
          }, 1040, ["onClick"])
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(_ctx.modeStore.darkMode ? 'bg-blue-grey-darken-3 d-flex justify-center align-center pa-2 rounded-lg' : 'bg-blue-grey-lighten-3 d-flex justify-center align-center pa-2 rounded-lg')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "text-uppercase text-wrap text-center w-75" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-notebook",
                size: "xx-large"
              }),
              _createTextVNode(" Add Routine as Workout ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "d-flex flex-column justify-center align-center w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                name: "routine",
                id: "routine",
                ref: "routine",
                modelValue: _ctx.routineName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.routineName) = $event)),
                label: "Select Routine",
                items: _ctx.routineStore.routines.map(routine => routine.attributes.name),
                class: "w-100"
              }, null, 8, ["modelValue", "items"]),
              _createVNode(_component_v_btn, {
                title: "Log Routine as Workout",
                onClick: _ctx.useRoutine,
                "prepend-icon": "mdi-notebook",
                class: "bg-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Log Routine")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_CloseButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}