
// Vue imports
import { defineComponent, ref } from 'vue';
// Pinia stores
import { useStatStore } from '@/stores/statStore';
import { useModeStore } from '@/stores/modeStore';
// Third-party components
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { MeasurementData } from '@/types/index';
// Local components
import InfoAlert from '../alerts/InfoAlert.vue';

export default defineComponent({
    data () {
        const statStore = useStatStore();
        const modeStore = useModeStore();
        const muscle = '';
        const items = ['Upper Arm', 'Forearm', 'Chest', 'Thigh', 'Calf', 'Waist', 'Shoulder'];
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });

        return ({
            statStore,
            modeStore,
            muscle,
            items,
            month
        });
    },
    components: {
        VueDatePicker,
        InfoAlert
    },
    computed: {
        measurements (): MeasurementData[] {
            return this.statStore.measurements.filter(measurement => {
                const year = Number(measurement.attributes.date.slice(0, 4));
                const month = Number(measurement.attributes.date.slice(5, 7)) - 1;

                if (measurement.attributes.muscle === this.muscle && year === this.month.year && month === this.month.month) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    }
});
