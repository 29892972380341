import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_SetForm = _resolveComponent("SetForm")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "bg-blue-grey-darken-4 pa-2 rounded-lg" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: 10,
            class: "ma-0 pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                label: "Exercise",
                items: _ctx.exercises.map(exercise => exercise.name),
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = newValue => _ctx.updateName(newValue))
              }, null, 8, ["items", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: 2,
            class: "my-0 pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tooltip, { text: "Delete Exercise" }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_DeleteButton, _mergeProps(props, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal === 'workout' ? _ctx.workoutStore.deleteEntry(_ctx.entry) : _ctx.routineStore.deleteEntry(_ctx.entry)))
                  }), null, 16)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: 12,
            class: "ma-0 pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_slider, {
                step: 1,
                min: 1,
                max: 6,
                label: "Sets",
                "show-ticks": "always",
                "tick-size": "6",
                ticks: _ctx.tickLabels,
                "prepend-icon": "mdi-weight-lifter",
                modelValue: _ctx.sets,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = newValue => _ctx.updateSetCount(_ctx.sets, newValue))
              }, null, 8, ["ticks", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entry.sets, (set) => {
        return (_openBlock(), _createBlock(_component_v_row, {
          key: set.key,
          class: "d-flex flex-wrap justify-center align-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: 12,
              class: "my-0 py-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SetForm, {
                  entry: _ctx.entry,
                  set: set,
                  modal: _ctx.modal
                }, null, 8, ["entry", "set", "modal"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}