import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_CalendarModal = _resolveComponent("CalendarModal")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_AddRoutineModal = _resolveComponent("AddRoutineModal")!
  const _component_AddWorkoutModal = _resolveComponent("AddWorkoutModal")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    class: "px-5 py-1",
    color: _ctx.modeStore.darkMode ? 'blue-grey-darken-4' : 'blue-lighten-3',
    density: "compact",
    extended: ""
  }, {
    extension: _withCtx(() => [
      _createVNode(_component_v_toolbar_items, { class: "w-100 d-flex justify-space-evenly align-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, {
            text: "Change Date Back",
            "open-delay": 500
          }, {
            activator: _withCtx(({ props }) => [
              (_ctx.windowStore.width >= 420)
                ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                    class: "d-flex justify-center align-center",
                    variant: "plain",
                    onClick: _ctx.workoutStore.changeDateBack
                  }), {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-chevron-left",
                        size: "xxx-large"
                      })
                    ]),
                    default: _withCtx(() => [
                      (_ctx.windowStore.width >= 600)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Back"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1040, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_CalendarModal),
          _createVNode(_component_v_tooltip, {
            text: "Change Date Forward",
            "open-delay": 500
          }, {
            activator: _withCtx(({ props }) => [
              (_ctx.windowStore.width >= 420)
                ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
                    class: "d-flex justify-center align-center",
                    variant: "plain",
                    onClick: _ctx.workoutStore.changeDateForward
                  }), {
                    append: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-chevron-right",
                        size: "xxx-large"
                      })
                    ]),
                    default: _withCtx(() => [
                      (_ctx.windowStore.width >= 600)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Forward"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1040, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_toolbar_items, null, {
        default: _withCtx(() => [
          _createVNode(_component_AddRoutineModal, {
            onShowSnackBar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showSnackBar')))
          }),
          _createVNode(_component_AddWorkoutModal, {
            onShowSnackBar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showSnackBar')))
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "flex align-center text-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { icon: "mdi-weight-lifter" }),
          _createTextVNode(" My Workouts ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}